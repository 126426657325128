@font-face {
  font-family:  "SuisseIntl-Bold";
  src: url("suisseintl-bold-webfont.woff2") format("woff2"),
    url("suisseintl-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "SuisseIntl-Medium";
  src: url("suisseintl-medium-webfont.woff2") format("woff2"),
    url("suisseintl-medium-webfont.woff") format("woff");
}

@font-face {
  font-family: "SuisseIntl-Regular";
  src: url("suisseintl-regular-webfont.woff2") format("woff2"),
    url("suisseintl-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("Poppins-SemiBold.woff2") format("woff2"),
    url("Poppins-SemiBold.woff") format("woff");
}

